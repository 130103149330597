import {
	ajax,
	BODY,
	Button,
	Header,
	Heading,
	HEADING_LEVELS,
	HUNDRED_PERCENT,
	INLINE_BLOCK,
	IS_PHONE,
	PIXELS,
	SearchBar,
	SPACE,
	stringHelper,
	theme,
	TILE_COLUMN_ALIGN,
	TileLayout
} from 'CigarKrakenFrontEndCommon';
import { assign, clamp, compact, concat, delay, each, filter, find, join, map, remove, sortBy, trim } from 'lodash';
import * as OfflinePluginRuntime from 'offline-plugin/runtime';
import "./main.dark.desktop.less";
import "./main.dark.mobile.less";
import "./main.light.desktop.less";
import "./main.light.mobile.less";
import Tile, { buildImageUrl } from './Tile';

OfflinePluginRuntime.install();

const MAIN_CONTENT_PADDING = 20;
const TITLE = 'Darren Paul Wright';
const SUB_TITLE = 'Art Works';
const SEARCH_BAR_ID = 'searchBar';
const GROUP_SEPARATOR = ': ';

const Main = function() {
	let resources = [];
	let mainLayout;
	let filteredItems;
	let header;
	let welcomeImage;
	let aboutText;
	let socialLinks;

	const construct = function() {
		theme.themes(['dark', 'light']);

		Promise.all([
				ajax.get('localization/common-en-us.json'),
				ajax.get('about.json')
			])
			.then(function(results) {
				const localizedStrings = assign(results[0], results[1]);
				aboutText = results[1].text;
				socialLinks = results[1].links;

				mainLayout = new TileLayout({
					container: BODY,
					padding: MAIN_CONTENT_PADDING + PIXELS,
					onResize: (windowWidth, windowHeight) => {
						const searchBar = header.get(SEARCH_BAR_ID).searchBarContainer();
						const searchBarHeight = searchBar ? searchBar.height() : 0;
						const top = header.height() + searchBarHeight;

						mainLayout
							.margin(top + 'px 0 0')
							.height(windowHeight - top);
					},
					height: HUNDRED_PERCENT,
					tileWidth: '16rem',
					maxColumns: 8,
					minColumns: 2,
					tileControl: Tile,
					columnAlign: IS_PHONE ? TILE_COLUMN_ALIGN.STRETCH : TILE_COLUMN_ALIGN.CENTER,
					tileDefaultSettings: {
						getLightBoxImages: getLightBoxImages
					},
					onTileRender: (control, data) => {
						return new Promise(function(resolve) {
							console.log('control: ', control);
							console.log('data: ', data);
							control
								.data(data)
								.title(data.title || '')
								.description(data.description || '')
								.group(data.group)
								.resize(true);

							resolve();
						});
					}
				});

				header = new Header({
					container: BODY,
					content: [{
						control: Heading,
						title: TITLE,
						level: HEADING_LEVELS.ONE,
						margin: '0 0 0 1rem',
						css: {
							display: INLINE_BLOCK
						}
					}, {
						ID: SEARCH_BAR_ID,
						control: SearchBar,
						localizedStrings: localizedStrings,
						onChange: function(newValue) {
							const QUOTE = '"';
							delay(() => {
								filterResources(join(map(newValue, (value) => {
									return (value.indexOf(SPACE) === -1) ? value : QUOTE + value + QUOTE;
								}), ' '));
							}, 100);
						}
					}, {
						control: Button,
						classes: 'header-button align-right',
						alt: 'Change theme',
						isSelectable: true,
						icon: '',
						onClick: () => {
							theme.theme(theme.theme() === 'dark' ? 'light' : 'dark');
						}
					}]
				});

				ajax.get('resources.json')
					.then(buildTagData);
			});
	};

	const buildTagData = (data) => {
		const MAX_YEAR = 10000;
		const LOW_PRIORITY = 100;

		const buildSort = (item) => {
			let sort = (item.year ? MAX_YEAR - parseInt(item.year) : MAX_YEAR) * LOW_PRIORITY;
			sort += item.priority ? parseInt(item.priority) : LOW_PRIORITY;

			return sort;
		};

		const buildGroup = (item) => {
			let folders = item.public_id.split('\/');
			folders.pop();

			return join(folders, GROUP_SEPARATOR);
		};

		const buildSearchString = (item) => {
			let searchString = [];

			each(['title', 'description', 'tags', 'group', 'year'], (key) => {
				searchString = concat(searchString, item[key]);
			});

			return join(searchString, SPACE);
		};

		resources = compact(map(data, (item) => {
			item = assign({
				tags: []
			}, item, {
				ID: item.public_id,
				group: buildGroup(item),
				columnSpan: clamp(Math.floor(item.width / item.height), 1, 3),
				sort: buildSort(item)
			});
			item.searchString = buildSearchString(item);

			delete item.public_id;

			if (!welcomeImage || item.welcome) {
				welcomeImage = item;
			}

			return item;
		}));

		remove(resources, {
			ID: welcomeImage.ID
		});

		resources = sortBy(resources, ['sort']);

		filterResources('');
	};

	const filterResources = (value) => {
		let suggestions = [];

		const addSuggestion = (title, ID) => {
			title = trim(title);

			if (title) {
				const prior = find(suggestions, {
					title: title
				});

				if (prior) {
					if (prior.itemIDs.indexOf(ID) === -1) {
						prior.subTitle++;
						prior.itemIDs.push(ID);
					}
				}
				else {
					suggestions.push({
						ID: title,
						title: title,
						subTitle: 1,
						itemIDs: [ID]
					});
				}
			}
		};

		filteredItems = filter(resources, (item) => {
			return stringHelper.isEachInString(value || '', item.searchString || '');
		});

		if (value) {
			filteredItems = sortBy(filteredItems, ['priority', 'sort'])
		}
		else {
			filteredItems = sortBy(filteredItems, ['sort'])
		}

		each(filteredItems, (item) => {
			let groups = [];

			each(item.tags, (tag) => {
				addSuggestion(tag, item.ID);
			});
			each(item.group.split(GROUP_SEPARATOR), (group) => {
				groups.push(group);
				addSuggestion(join(groups, GROUP_SEPARATOR), item.ID);
			});
			if (item.title && value) {
				each(item.title.split(' '), (word) => {
					addSuggestion(word, item.ID);
				});
			}

			groups = null;
		});

		header.get(SEARCH_BAR_ID)
			.suggestions(map(suggestions, (suggestion) => {
				return {
					ID: suggestion.ID,
					title: suggestion.title,
					subTitle: ' (' + suggestion.subTitle + ')'
				}
			}))
			.countText(resources.length === filteredItems.length ? resources.length + '' : filteredItems.length + '/' + resources.length);

		if (!value) {
			filteredItems.unshift(assign({}, welcomeImage, {
				title: TITLE,
				description: SUB_TITLE,
				aboutText: aboutText,
				socialLinks: socialLinks,
				columnSpan: 4,
				welcome: true
			}));
		}

		mainLayout.tileData(filteredItems);

		suggestions = null;
	};

	const getLightBoxImages = () => {
		return map(filteredItems, (resource) => {
			return {
				name: resource.ID,
				thumbSource: buildImageUrl(resource, 220),
				fileData: buildImageUrl(resource)
			}
		});
	};

	construct();
};

new Main();
