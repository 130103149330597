import {
	CLICK_EVENT,
	Container,
	CssSize,
	Description,
	Dialog,
	EXTERNAL_LINK_ICON,
	Heading,
	HEADING_LEVELS,
	HUNDRED_PERCENT,
	Hyperlink,
	ICON_SIZES,
	Image,
	LightBox,
	method,
	MORE_ICON,
	objectHelper,
	PIXELS,
	Span,
	Toolbar,
	TOP
} from 'CigarKrakenFrontEndCommon';
import { map } from 'lodash';
import "./Tile.dark.desktop.less";
import "./Tile.dark.mobile.less";
import "./Tile.light.desktop.less";
import "./Tile.light.mobile.less";

export const buildImageUrl = (data, renderWidth) => {
	renderWidth = renderWidth ? 'w_' + renderWidth + '/' : '';

	return 'https://res.cloudinary.com/dgrs7ihzn/image/upload/' + renderWidth + data.ID + '.' + data.format
};

export default function Tile(settings = {}) {
	const self = this;
	let image;
	let title;
	let linkBar;
	let isWelcome = false;
	let welcomeDescription;
	let moreLink;
	const imageWidth = new CssSize();
	const welcomePadding = new CssSize('2rem');

	Container.call(self);

	const construct = () => {
		self.addClass('tile')
			.on(CLICK_EVENT, showLightbox);

		image = new Image({
			container: self,
			maxWidth: HUNDRED_PERCENT,
			on: {
				load: () => {
					image.isVisible(true);
					self.isWorking(false);
				}
			}
		});

		title = new Heading({
			container: self,
			level: HEADING_LEVELS.FIVE,
			isInline: false,
			isDisplayed: false
		});

		objectHelper.applySettings(self, settings);

		self.onResize(resizeTitle);
	};

	const resizeTitle = () => {
		if (isWelcome) {
			const selfWidth = self.width();
			const renderedImageWidth = image.width();
			const isWide = (selfWidth / renderedImageWidth > 2);
			const textWidth = isWide ? (selfWidth - renderedImageWidth - welcomePadding.toPixels(true) * 3 - 1) + PIXELS : self.width() - (welcomePadding.toPixels(true) * 2) + PIXELS;

			image.height(renderedImageWidth);

			title
				.width(textWidth)
				.classes('wide', isWide);

			welcomeDescription
				.width(textWidth)
				.css(TOP, title.height() + welcomePadding.toPixels(true) + PIXELS)
				.classes('wide', isWide);

			moreLink
				.width(textWidth)
				.css(TOP, title.height() + welcomeDescription.height() + welcomePadding.toPixels(true) + PIXELS)
				.classes('wide', isWide);
		}
		else {
			title.width(HUNDRED_PERCENT);
		}
	};

	const setTitle = () => {
		if (self.title() || self.description()) {
			title
				.title(self.title())
				.subTitle(self.description())
				.isDisplayed(true)
				.isInline(!self.description())
				.resize(true);
		}
		else {
			title.isDisplayed(false);
		}
	};

	const showLightbox = () => {
		if (!self.data().welcome) {
			new LightBox({
				files: self.getLightBoxImages()(),
				selectedItems: [self.ID()],
				title: self.group()
			});
		}
	};

	const setWelcomeMessage = () => {
		if (isWelcome) {
			welcomeDescription = new Description({
				container: self,
				value: self.data().aboutText.substring(0, 591) + '…</p>'
			});
			moreLink = new Hyperlink({
				container: self,
				text: 'more ' + MORE_ICON,
				onClick: showAboutDialog
			});
		}
		else if (moreLink) {
			welcomeDescription.remove();
			welcomeDescription = null;
			moreLink.remove();
			moreLink = null;
		}
	};

	const showAboutDialog = () => {
		new Dialog({
			title: 'About Darren Paul Wright',
			height: '100%',
			width: '32rem',
			content: [{
				control: Image,
				source: buildImageUrl(self.data(), 200),
				width: '50%',
				maxWidth: '12rem',
				margin: '0 1rem 1rem 0',
				css: {
					float: 'left'
				}
			}, {
				control: Description,
				value: self.data().aboutText,
				margin: '0 0 1em',
				css: {
					display: 'block',
					float: 'none'
				}
			}, {
				control: Hyperlink,
				url: 'https://docs.google.com/document/d/1YoJfcQhujgYOQC9-H3lB2Mo2vg9YN3tidMs1n1ol9Bs/edit?usp=sharing',
				text: 'Resume ' + EXTERNAL_LINK_ICON
			}, {
				control: Span,
				text: '•',
				css: {
					margin: '0 1em'
				}
			}, {
				control: Hyperlink,
				url: 'Darren@DarrenPaulWright.com'
			}, {
				control: Toolbar,
				padding: '0',
				margin: '1rem 0 0',
				css: {
					float: 'right'
				},
				content: getLinkButtons()
			}]
		});
	};

	const getLinkButtons = () => {
		return map(self.data().socialLinks, (data) => {
			return {
				icon: data.icon,
				iconSize: ICON_SIZES.TWO_TIMES,
				alt: data.title,
				classes: 'icon-button',
				url: data.link
			};
		});
	};

	const setLinkBar = () => {
		if (isWelcome) {
			linkBar = new Toolbar({
				container: self,
				padding: '0',
				margin: '1rem 0 0',
				content: getLinkButtons()
			});
		}
		else if (linkBar) {
			linkBar.remove();
			linkBar = null;
		}
	};

	self.data = method.object({
		set: (data) => {
			console.log('data: ', data);
			self.isWorking(true);
			isWelcome = data.welcome || false;
			const columnSpan = self.attr('columnspan');
			imageWidth.set(isWelcome ? '30rem' : (columnSpan * 220) + ((columnSpan - 1) * 48) + 'px');
			const renderRatio = self.innerWidth() / imageWidth.toPixels(true);
			const renderWidth = imageWidth.toPixels(true) * (isWelcome ? 1 : renderRatio);

			image
				.isVisible(false)
				.source(buildImageUrl(data, isWelcome ? 488 : imageWidth.toPixels(true)))
				.width(renderWidth + PIXELS)
				.height(((data.height / data.width) * renderWidth) + PIXELS);

			title
				.canWrap(isWelcome);

			self
				.classes('tile', !isWelcome)
				.classes('welcome', isWelcome);

			setWelcomeMessage();
			setLinkBar();
			resizeTitle();
		}
	});

	self.title = method.string({
		set: setTitle
	});

	self.description = method.string({
		set: setTitle
	});

	self.group = method.string();

	self.getLightBoxImages = method.func();

	construct();
}
